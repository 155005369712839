/* BASIC */
body {
  font-family: "Poppins", sans-serif;
  height: 100vh;
}

a {
  color: #353b50;
  display: inline-block;
  text-decoration: none;
  font-weight: 400;
}

/*h2 {*/
/*  text-align: center;*/
/*  font-size: 16px;*/
/*  font-weight: 600;*/
/*  text-transform: uppercase;*/
/*  display:inline-block;*/
/*  margin: 40px 8px 10px 8px; */
/*  color: #cccccc;*/
/*}*/

/* STRUCTURE */

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

/* TABS */

h2.inactive {
  color: #cccccc;
}

h2.active {
  color: #eaeaea;
  border-bottom: 2px solid #353b50;
}

/* FORM TYPOGRAPHY*/

input[type="button"],
input[type="submit"],
input[type="reset"] {
  background-color: #353b50;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

input[type="button"]:hover,
input[type="submit"]:hover,
input[type="reset"]:hover {
  background-color: #696d7c;
}

input[type="button"]:active,
input[type="submit"]:active,
input[type="reset"]:active {
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -o-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

input[type="text"],
input[type="password"],
select,
textarea {
  font-size: 0.9rem;
  border-color: #dfe0e6;
  padding: 14px 20px;
  border-radius: 4px;
  color: #353b50;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  /* width: 85%; */
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  outline: none;
}

input[type="text"]:focus,
input[type="password"]:focus {
  background-color: #fff;
  border-bottom: 2px solid #353b50;
}

input[type="text"]:placeholder,
input[type="password"]:placeholder {
  color: #cccccc;
}

/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #353b50;
  content: "";
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #eaeaea;
}

.underlineHover:hover:after {
  width: 100%;
}

/* OTHERS */

*:focus {
  outline: none;
}

#icon {
  width: 60%;
  padding-top: 35px;
}

.setting-options {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.setting-option {
  height: 220px;
  width: 210px;
  border-radius: 1rem;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.16);
  padding: 20px;
  margin: 20px auto;
}

.setting-option {
  text-decoration: none;
}

a.setting-option:hover {
  text-decoration: none !important;
}

.setting-option p {
  font-size: 0.8rem;
  font-weight: 600;
  text-align: center;
}

.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: 128px;
  padding: 20px;
}

.img-container img {
  max-height: 80%;
  max-width: 80%;
}

.btn-primary {
  background-color: #353b50;
  border-color: #353b50;
}
.success-notification {
  display: block;
  z-index: 15;
  min-width: 30rem !important;
  position: absolute;
  right: 10rem;
  top: 2rem;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:disabled {
  background-color: #81879c !important;
  border-color: #81879c !important;
}

.bg-gradient-primary {
  background-color: #353b50;
  background-image: linear-gradient(180deg, #353b50 10%, #353b50 100%);
}

.text-primary {
  color: #353b50 !important;
}

.dropdown-item:active {
  background-color: #353b50 !important;
}

#partner td:last-child {
  max-width: 150px;
  width: 115px;
}
#partner {
  padding-top: 0px;
}

.partner_detail {
  border-bottom: 1px solid #d3d3d3;
  margin-bottom: 20px;
  padding-bottom: 10px;
  line-height: 1rem;
  font-size: 18px;
}

.partner_detail b {
  min-width: 177px;
  display: inline-block;
}

form input,
form select,
form textarea {
  width: 100% !important;
  text-align: left !important;
}
#login_form input {
  width: 85% !important;
  text-align: center !important;
}
.custom-control-label::before {
  top: 0.05rem;
}
.custom-control-label::after {
  top: 0.05rem;
}
.custom-control-input {
  z-index: 1 !important;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #353b50;
  background-color: #353b50;
}
.page-item.active .page-link {
  background-color: #353b50;
  border-color: #353b50;
}
.page-link:hover {
  color: #353b50;
}
.page-link {
  color: #353b50;
}
/* .form-control:focus {
  border: none;
  box-shadow: none;
} */
.dropdown-item.active {
  background-color: #353b50;
}
.bootstrap-table .search {
  width: 100%;
}
.bootstrap-table .search input {
  text-align: left;
}
@media screen and (max-width: 1900px) {
  .setting-options {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
}
@media screen and (max-width: 1600px) {
  .setting-options {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 1366px) {
  .setting-options {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

.ca-admin-form {
  display: table;
  width: 100%;
}

.ca-admin-form__form-field {
  display: table-row;
}

.ca-admin-form__form-field label {
  display: table-cell;
  text-align: right;
  font-weight: bold;
  padding: 0 20px 0 0;
}

.ca-admin-form__form-field .input-field {
  display: table-cell;
}

.ca-admin-table-actions a {
  margin: 0 5px;
}

.ca-admin-pagination {
  display: flex;
  align-items: center;
}

.ca-admin-pagination .pagination {
  margin: 0;
}

.ca-admin-pagination__pagination-details {
  flex: 50%;
}

.ca-admin-pagination__pagination-details label {
  display: inline-block;
  margin-right: 1rem;
}

.modal.show {
  display: block;
}
